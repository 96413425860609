<div class="navbar_container">
  <div class="contenedor_principal">
    <div class="terms_container">
      <p class="terms_title">
        ©2020 © The Coca‑Cola Company. All rights reserved.
      </p>
      <div class="subtitles_container">
        <div class="subtitles_container__termsAndPrivacy">
          <span class="terms_subtitle_1" (click)="onNavigateTerms()">TÉRMINOS</span>
          <span class="terms_subtitle_2" (click)="onNavigatePrivacy()">PRIVACIDAD</span>
        </div>
      </div>
    </div>
    <hr class="divider" />
    <div class="terms_container_2">
      <p class="terms">
        Coca-Cola de Chile S.A., respeta el derecho de los usuarios del sitio a
        la protección de sus datos personales, los cuales están amparados por la
        Ley N° 19.628 sobre Protección de la Vida Privada, y se compromete a
        proteger la seguridad de sus datos de carácter personal. Los datos que
        los usuarios proporcionen a la Compañía a través de nuestro Sitio se
        encuentran sujetos a nuestra Política de Privacidad, disponible en
        https://www.cocacolalatinamerica.com/cl/es/politicas-de-privacidad
      </p>
    </div>
  </div>
</div>
