import { Component, Input, ViewChild, ElementRef, OnInit } from '@angular/core';
import { CityService } from 'src/app/services/city.service';
declare let gtag: Function;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  @Input() cities;
  @Input() agreed;
  @Input() disagreed;
  @ViewChild('cityInput') input: ElementRef;
  selectedCityId = null;
  valueCity: string;

  andinaCityRedirect = [
    15,
    16,
    17,
    19,
    20
  ];

  andinaCitiesGtag = [
    { id: 15, name: 'Santiago de Chile' },
    { id: 16, name: 'Coquimbo' },
    { id: 17, name: 'La Serena' },
    { id: 19, name: 'Rancagua' },
    { id: 20, name: 'Machalí' },
  ];

  valueEmit: string;

  constructor(private cityService: CityService) { }

  ngOnInit(): void {
    this.cityService.selectedCityId.subscribe(
      (value) => (this.selectedCityId = value));
  }

  //Fired when user clicks on 'Comprar Aqui' button
  redirect(): void {
    let url = '';
    const zones = this.cities.flatMap(({ zones }) => zones);
    const currentCity = zones.find(
      (item) => item.googleEmbedId === this.selectedCityId
    );
    this.cityService.changeCity(this.selectedCityId);
    if (currentCity) {
      gtag('event', 'Selector Ciudad', {
        event_category: 'PreHome',
        event_label: 'Ciudad',
        value: currentCity.name
      });
      localStorage.setItem('savedLocation', currentCity.id);
      url = `http://embonor.micoca-cola.cl/?city=${currentCity.name}`;
      console.log(`Se envió ${currentCity.name} como ciudad elegida.`);
    } else {
      if (this.andinaCityRedirect.includes(this.selectedCityId)) {
        const currentAndinaCity = this.andinaCitiesGtag.find(res => {
          return this.selectedCityId === res.id;
        })
        gtag('event', 'Selector Ciudad', {
          event_category: 'PreHome',
          event_label: 'Ciudad',
          value: currentAndinaCity.name
        });
        localStorage.setItem('savedLocation', currentAndinaCity.id.toString());
        url = 'http://andina.micoca-cola.cl/?utm_source=micoca-cola&utm_medium=referral&utm_campaign=pre-home';
        console.log(`Se envió ${currentAndinaCity.name} como ciudad elegida.`);
      }
    }
    window.open(url, '_self');
  }

  out(res) {
    this.selectedCityId = res;
  }

  onNavigateTerms() {
    const url =
      'https://www.cocacolalatinamerica.com/cl/es/terminos-y-condiciones';
    window.open(url, '_self');
  }

  onNavigatePrivacy() {
    const url =
      'https://www.cocacolalatinamerica.com/cl/es/politicas-de-privacidad';
    window.open(url, '_self');
  }
}
