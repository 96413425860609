import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  onNavigateTerms() {
    const url =
      "https://www.cocacolalatinamerica.com/cl/es/terminos-y-condiciones";
    window.open(url, "_self");
  }

  onNavigatePrivacy() {
    const url =
      "https://www.cocacolalatinamerica.com/cl/es/politicas-de-privacidad";
    window.open(url, "_self");
  }
}
