<div class="select-box">
  <!-- Ciudad seleccionada / Current select value -->
  <div class="select-box__current" tabindex="1">
    <div class="select-box__value">
      <input class="select-box__input" type="radio" id="0" value="1" name="Ben" checked="checked"/>
      <p class="select-box__input-text">Descubre si llegamos a tu zona</p>
    </div>
    <ng-container *ngFor="let item of orderCities" class="select-box">
      <div *ngFor="let city of item.zones" class="select-box__value">
        <input class="select-box__input" type="radio" id="{{ city.id }}" value="{{ city.id }}" (change)="handleClick(city.type, city.id)" name="Ben" />
        <p class="select-box__input-text">{{ city.name }}</p>
      </div>
    </ng-container>
    <img class="select-box__icon" src="assets/arrow-down.png" alt="Lupa Icon" aria-hidden="true"/>
  </div>
  <!-- Fin ciudad seleccionada-->

  <!-- Listado de Regiones-Ciudades -->
  <ul class="select-box__list">
    <li>
      <label class="select-box__option" for="0" aria-hidden="aria-hidden" style="display: none;">Descubre si llegamos a tu zona</label>
    </li>
    <div *ngFor="let item of orderCities">
      <strong><label class="select-box__option__parent" aria-hidden="aria-hidden">{{ item.name }}</label></strong>
      <li *ngFor="let city of item.zones" class="span_list">
        <span class="span_list--item"></span>
        <label class="select-box__option" for="{{ city.id }}" aria-hidden="aria-hidden">{{ city.name }}</label>
      </li>
    </div>
  </ul>
  <!-- Fin Listado de Regiones-Ciudades -->
</div>