import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';

import { AgmCoreModule } from '@agm/core';
import { CookieService } from 'ngx-cookie-service';
import { CityService } from './services/city.service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ModalComponentComponent } from './modal-component/modal-component.component';
import { GeolocationComponent } from './geolocation/geolocation.component';
import { HomeComponent } from './pages/home/home.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';

@NgModule({
  declarations: [
    AppComponent,
    ModalComponentComponent,
    GeolocationComponent,
    HomeComponent,
    NavbarComponent,
    DropdownComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    AppRoutingModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCTqEvAxpgw07Ob386vT62HpAQnztRiFk0',
    }),
  ],
  providers: [ CookieService, CityService ],
  bootstrap: [AppComponent],
})
export class AppModule {}
