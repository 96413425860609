import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  OnChanges,
} from '@angular/core';
import * as _ from 'underscore';
import { CityService } from 'src/app/services/city.service';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent implements OnInit, OnChanges {
  @Input() cities = [];
  currentCity: any = {};
  @Output() valueEmit = new EventEmitter();
  isIE = false;

  orderCities = [];

  citiesCustomSelected = [
    'santiago',
    'coquimbo',
    'la-serena',
    'machalí',
    'rancagua',
    /*'guanaqueros',
    'las-tacas',
    'morrillos',
    'playa-blanca',
    'puerto-velero',
    'Tongoy',
    'Totoralillo',*/
  ];

  constructor(
    private cityService: CityService
  ) { }

  ngOnInit(): void {
    if (window.navigator.userAgent.match(/MSIE|Trident/)) {
      this.isIE = true;
    }

    this.cityService.selectedCityId.subscribe((value) => {
      let zones = this.cities.flatMap(({ zones }) => zones);
      this.currentCity = zones.find(
        (item) => item.googleEmbedId === value
      );
    });
  }

  ngOnChanges() {
    this.cities.push(
      {
        "id": 1,
        "name": "Región Metropolitana",
        "zones": [
          {
            "id": 15,
            "name": "Santiago",
            "andina": true,
            "type": 'santiago'
          }
        ]
      },
      {
        "id": 4,
        "name": "Coquimbo",
        "zones": [
          {
            "id": 16,
            "name": "Coquimbo",
            "andina": true,
            "type": 'coquimbo'
          },
          {
            "id": 17,
            "name": "La Serena",
            "andina": true,
            "type": 'la-serena'
          }
        ]
      },
      {
        "id": 6,
        "name": "Lib. Gral Bernardo O'Higgins",
        "zones": [
          {
            "id": 20,
            "name": "Machalí",
            "andina": true,
            "type": 'machalí'
          },
          {
            "id": 19,
            "name": "Rancagua",
            "andina": true,
            "type": 'rancagua'
          }
        ]
      }
    );

    this.orderCities = _.sortBy(this.cities, 'id');
  }

  onCitySelected(value): void {
    this.cityService.changeCity(
      this.cities.find((item) => item.id === +value)?.googleEmbedId
    );
  }

  customCitySelect(cityType: string, id: string): void {
    if (this.citiesCustomSelected.includes(cityType)) {
      this.valueEmit.emit((id));
    }
  }

  handleClick(cityType: string, id: string): void {
    if (cityType) {
      if (this.citiesCustomSelected.includes(cityType)) {
        this.valueEmit.emit(id);
      }
    } else {
      let zones = [].concat(...this.cities.map(({ zones }) => zones));
      let selectedZone = zones.find((item) => item.id === +id);
      
      if (selectedZone) {
        this.cityService.changeCity(selectedZone.googleEmbedId);
      }
    }
  }
  
}
