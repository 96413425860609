import {
  Component,
  ViewChild,
  ElementRef,
  EventEmitter,
  Input,
  Output, OnInit, ChangeDetectorRef
} from '@angular/core';
import { CityService } from '../services/city.service';
declare let gtag: Function;

@Component({
  selector: 'app-modal-component',
  templateUrl: './modal-component.component.html',
  styleUrls: ['./modal-component.component.scss'],
})

export class ModalComponentComponent implements OnInit {

  @Input() lat;
  @Input() lng;
  @Input() address;
  @Input() currentCityId;
  @Input() cities = [];
  @ViewChild('myModal', { static: false }) modal: ElementRef;
  @Output() voted = new EventEmitter<boolean>();
  openModal = true;

  constructor(
    private cityService: CityService,
    private _changeDetector: ChangeDetectorRef,
    ) {}

  ngOnInit(): void {
    this.cityService.selectedCityId.subscribe(
      (value) => (this.currentCityId = value)
    );
  }

  closeModal(agreed: boolean): void {
    this.voted.emit(agreed);
    // We call the event emmiter function from our service and pass in the details
    this.cityService.changeCity(this.currentCityId);
    const currentCity = this.cities.find(
      (item) => item.googleEmbedId === this.currentCityId
    );
    if (agreed) {
      gtag('event', 'Geolocalizacion', {
        event_category: 'Ubicacion correcta',
        event_label: 'Prehome Chile'
      });
      
      if (currentCity) {
        gtag('event', currentCity.name, {
          event_category: 'Modal',
          event_label: 'Prehome Chile'
        });
        localStorage.setItem('savedLocation', currentCity.id);
        window.open('https://ventahogar.cl/', '_self');
      } else {
        if (this.currentCityId === '15') {
          gtag('event', 'Santiago', {
            event_category: 'Modal',
            event_label: 'Prehome Chile'
          });
          localStorage.setItem('savedLocation', '15');
          window.open('http://embonor.micoca-cola.cl/', '_self');
        } else {
          gtag('event', 'Geolocalizacion', {
            event_category: 'Ubicacion incorrecta',
            event_label: 'Prehome Chile'
          });
        }
      }
    } else {
      gtag('event', 'Geolocalizacion', {
        event_category: 'Ubicacion incorrecta',
        event_label: 'Prehome Chile'
      });
    }
    this.openModal = false;
    this._changeDetector.detectChanges();
  }

  hasAnAddress(address): string {
    return address ? address : this.closeModal(false);
  }
}
