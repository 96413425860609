import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-geolocation',
  templateUrl: './geolocation.component.html',
  styleUrls: ['./geolocation.component.scss']
})
export class GeolocationComponent implements OnInit{

  lat;
  lng;
  zoom;
  origin;
  destination;

  ngOnInit(): void {
    this.getUserLocation();
  }

  getUserLocation(): void {
    // get Users current position

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
        this.zoom = 16;
      });
    }
  }

  async getDirection() {
    if (typeof this.lat === 'undefined' || typeof this.lng === 'undefined' || typeof this.zoom === 'undefined') {
      await this.getUserLocation();
    }
    this.origin = { lat: this.lat, lng: this.lng };
    this.destination = { lat: 24.799524, lng: 120.975017 };
  }
}
