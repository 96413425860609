<div class="container" *ngIf="openModal">
  <div class="content">
    <div class="close_popup">
      <div (click)="closeModal(false)" class="popup">+</div>
    </div>
    <div>
      <p>Actualmente te encuentras en:</p>
      <div class="ubicacion_info">
        <div class="address_container">
          <img class="img_pin" src="../../assets/pin_(5).svg" alt="geolocation_pin"/>
          <p class="address_txt" >{{ address }}</p>
        </div>
        <p>¿Es correcto?</p>
      </div>
      <div class="btn_container">
        <button class="btn_redirect--noColor" (click)="closeModal(false)">No, cambiar ubicacion</button>
        <button class="btn_redirect--color" (click)="closeModal(true)"> Si, continuar</button>
      </div>
    </div>
  </div>
</div>
