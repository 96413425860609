import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CityService {

  private cityId = new Subject<string>();
  selectedCityId = this.cityId.asObservable();

  constructor() { }

  changeCity(cityId: string): void {
    this.cityId.next(cityId);
  }
}
